import React from 'react';
import { Link } from 'react-router-dom';
import { Image } from '../../AbstractElements';
import logo from '../../assets/logo.png';
import { useSelector, useDispatch } from 'react-redux';

const SidebarLogo = () => {

  const brands = useSelector((state) => state.auth.brands);
  const selectedBrand = useSelector((state) => state.auth.user?.brand_id);

  return (
    <div className="logo-icon-wrapper">
      <Link to={`${process.env.PUBLIC_URL}/dashboard`}>
        {brands.filter(brand => brand.ID === selectedBrand)
          .map(b => (
            <Image
              key={b.ID}
              attrImage={{
                className: 'img-fluid for-dark',
                src: b.brand?.logo,
                alt: b.brand?.name || ''
              }}
            />
          ))}
      </Link>
    </div>
  );
};

export default SidebarLogo;
