import React, { useContext, useState } from 'react';
import { Image } from '../../AbstractElements';
import CheckContext from '../../_helper/customizer/index';
import { useSelector, useDispatch } from 'react-redux';

const SidebarIcon = () => {
  const { toggleSidebar } = useContext(CheckContext);
  const [toggle, setToggle] = useState(false);
  const openCloseSidebar = () => {
    setToggle(!toggle);
    toggleSidebar(toggle);
  };

  const brands = useSelector((state) => state.auth.brands);
  const selectedBrand = useSelector((state) => state.auth.user?.brand_id);
  console.log(brands);
  console.log(selectedBrand);
  return (
    <div className="logo-wrapper">
      <a href="#">
        {brands.filter(brand => brand?.brand.ID === selectedBrand)
          .map(b => (
            <Image
              key={b.ID}
              attrImage={{
                className: 'img-fluid for-light',
                src: b.brand?.logo,
                alt: b.brand?.name || 'BRAND NAME'
              }}
            />
          ))}
      </a>
      <div className='back-btn' onClick={() => openCloseSidebar()}><i className='fa fa-angle-left'></i></div>
    </div>
  );
};
export default SidebarIcon;
