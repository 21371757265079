import React, { useState, useCallback, useEffect } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Input } from 'reactstrap';
import Select from 'react-select';
import axios from 'axios';
import { toast } from 'react-toastify';
import { Search } from 'react-feather';
import DataTable from 'react-data-table-component';
import { HeaderApi, MainSearch } from '../../api/index';
import '../../styles/Searchbar.css';
import { Link } from 'react-router-dom';

const filterOptions = [
    { value: 'select_all', label: 'Select All' },
    { value: 'id', label: 'Lead ID' },
    { value: 'loan_no', label: 'Loan Number' },
    { value: 'pancard', label: 'Pan Card' },
    { value: 'first_name', label: 'First Name' },
    { value: 'mobile', label: 'Mobile' },
    { value: 'alternate_mobile', label: 'Alternate Mobile' },
    { value: 'email', label: 'Email' },
    { value: 'alternate_email', label: 'Alternate Email' },
];

const Searchbar = () => {
    const [isSearchModalOpen, setIsSearchModalOpen] = useState(false);
    const [isResultsModalOpen, setIsResultsModalOpen] = useState(false);
    const [searchValue, setSearchValue] = useState('');
    const [selectedFilters, setSelectedFilters] = useState(
        localStorage.getItem('selectedFilters') ? JSON.parse(localStorage.getItem('selectedFilters')) : []
    );
    const [searchResults, setSearchResults] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        localStorage.setItem('selectedFilters', JSON.stringify(selectedFilters));
    }, [selectedFilters]);

    // Toggle search modal
    const toggleSearchModal = () => setIsSearchModalOpen(!isSearchModalOpen);

    // Toggle results modal
    const toggleResultsModal = () => setIsResultsModalOpen(!isResultsModalOpen);

    // Fetch search results
    const fetchSearchResults = useCallback(async () => {
        if (!searchValue || selectedFilters.length === 0) {
            toast.error('Please enter a search value and select at least one filter.');
            return;
        }

        setLoading(true);
        try {
            const filters = selectedFilters
                .filter(filter => filter.value !== 'select_all')
                .map(filter => filter.value)
                .join(',');

            const response = await axios.get(MainSearch, {
                params: {
                    q: searchValue,
                    filters: filters,
                    page: 1,
                    limit: 20,
                },
                headers: HeaderApi,
            });

            setSearchResults(response.data.items || []);
            setIsSearchModalOpen(false); // Close the search modal
            setIsResultsModalOpen(true); // Open the results modal
        } catch (error) {
            console.error('Error fetching search results:', error);
            toast.error('Failed to fetch search results.');
        } finally {
            setLoading(false);
        }
    }, [searchValue, selectedFilters]);

    const handleFilterChange = (selectedOptions) => {
        if (selectedOptions.some(option => option.value === 'select_all')) {
            if (selectedOptions.length === filterOptions.length) {
                setSelectedFilters(selectedOptions.filter(option => option.value !== 'select_all'));
            } else {
                setSelectedFilters(filterOptions.filter(option => option.value !== 'select_all'));
            }
        } else {
            setSelectedFilters(selectedOptions);
        }
    };

    const clearFilters = () => {
        setSearchValue('');
        setSelectedFilters([]);
        // localStorage.removeItem('searchValue');
        localStorage.removeItem('selectedFilters');
    };

    const columns = [
        {
            name: 'ID',
            selector: row => row.id || '-',
            sortable: true,
        },
        {
            name: 'Name',
            selector: row => row.first_name || '-',
            sortable: true,
        },
        {
            name: 'Mobile',
            selector: row => row.mobile || '-',
        },
        {
            name: 'Loan Number',
            selector: row => row.loan_no || '-',
        },
        {
            name: 'Status',
            selector: row => row.status || '-',
        },
    ];

    return (
        <div className="searchbar">
            {/* Button to Open Search Options Modal */}
            <Link className="text-dark" to="#" onClick={toggleSearchModal}>
                <Search size={25} />
            </Link>

            {/* Modal for Search Options */}
            <Modal isOpen={isSearchModalOpen} toggle={toggleSearchModal} centered>
                <ModalBody>
                    <div className="form-group">
                        <label htmlFor="searchValue">Search Keyword:</label>
                        <Input
                            type="text"
                            id="searchValue"
                            placeholder="Enter search keyword"
                            value={searchValue}
                            onChange={(e) => setSearchValue(e.target.value.toUpperCase())}
                        />
                    </div>
                    <div className="form-group mt-3">
                        <label htmlFor="filters">Select Filters:</label>
                        <Select
                            isMulti
                            value={selectedFilters}
                            onChange={handleFilterChange}
                            options={filterOptions}
                            className="basic-multi-select"
                            classNamePrefix="select"
                            placeholder="Choose filters..."
                        />
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={fetchSearchResults} disabled={loading}>
                        {loading ? 'Searching...' : 'Search'}
                    </Button>
                </ModalFooter>
            </Modal>

            {/* Modal for Search Results */}
            <Modal isOpen={isResultsModalOpen} toggle={toggleResultsModal} centered size="xl">
                <ModalHeader toggle={toggleResultsModal}>Search Results</ModalHeader>
                <ModalBody>
                    {searchResults.length > 0 ? (
                        <DataTable
                            columns={columns}
                            data={searchResults}
                            paginationPerPage={5}
                            pagination
                            highlightOnHover
                            pointerOnHover
                            responsive
                            striped
                            onRowClicked={(row) => {
                                window.location.href = `${process.env.PUBLIC_URL}/lead-details/${row.id}/${row.lead_status_id}` || '#';
                            }}
                            noDataComponent={<div style={{ color: 'red', fontSize: 15 }}>No records found</div>}
                            paginationRowsPerPageOptions={[5, 10, 15, 20]}
                        />
                    ) : (
                        <p>No results found.</p>
                    )}
                </ModalBody>
            </Modal>
        </div>
    );
};

export default Searchbar;
